<template>
  <CCard class="p-4">
    <CCardBody>
      <h1>{{ $t('Forgot password') }}</h1>
      <p class="text-muted">{{ $t('Enter email assigned to your account') }}</p>
      <CForm @submit.prevent="handleSubmit">
        <CInput
          :placeholder="$t('Email')"
          autocomplete="email"
          v-model="email"
          name="email"
          required
        />
        <CButton
          color="primary"
          class="px-4 mt-2"
          type="submit"
          data-cy="reset-password-btn"
        >
          {{ $t('Reset password') }}
        </CButton>
      </CForm>
    </CCardBody>
  </CCard>
</template>

<script>
import { AuthService } from '@/services/auth';
import Message from "vue-m-message";

export default {
  name: 'ForgotPassword',
  data: function () {
    return {
      email: '',
    };
  },
  methods: {
    async handleSubmit() {
      await AuthService.forgotPassword(this.email);
      Message.success(this.$t("auth.request_success"), {
       position:'bottom-left'
      })
      this.$router.push({ name: 'Management Login' });
    },
  },
};
</script>
